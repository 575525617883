<template>
  <div>
    <!-- basic modal -->
    <b-modal id="modal-1" title="Basic Modal" ok-disabled hide-footer size="xl">
      <b-card-text>
        <small>Kode Program</small>
        <h5> {{ rs_detail_kegiatan.kode_program }}</h5>
        <small>Nama Program</small>
        <h5> {{ rs_detail_kegiatan.nama_program }}</h5>
        <hr>
        <!-- {{ rs_kegiatan }} -->
        <div v-for="kegiatan in rs_kegiatan" v-bind:key="kegiatan">
          <b-row class="text-primary">
            <b-col md="2"><strong>{{ kegiatan.kode_kegiatan }}</strong></b-col>
            <b-col md="10"><strong>{{ kegiatan.nama_kegiatan }}</strong>
            </b-col>
          </b-row>
          <hr>
          <detail-kegiatan-sub :items=kegiatan.sub_kegiatan></detail-kegiatan-sub>
          <!-- <div v-for="sub_kegiatan in kegiatan.sub_kegiatan" v-bind:key="sub_kegiatan">
            <b-row>
              <b-col md="4"><span class="ml-1">{{ sub_kegiatan.kode_sub_kegiatan }}</span></b-col>
              <b-col md="8">{{ sub_kegiatan.nama_sub_kegiatan }}</b-col>
            </b-row>

            <b-row>
              <b-col md="4"><span class="ml-1"> </span></b-col>
              <b-col md="8"> <b-alert variant="primary" show>
                  <div class="alert-body">
                    <small> <strong>Kinerja :</strong> {{ sub_kegiatan.kinerja }}<br><strong>Indikator :</strong> {{
                      sub_kegiatan.indikatror }}</small>
                  </div>
                </b-alert>
              </b-col>
            </b-row>
          </div> -->
          <hr>
        </div>
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DetailKegiatanSub from './DetailKegiatanSub.vue'

export default {
  components: {
    BButton,
    BModal,
    BAlert, BRow, BCol,
    DetailKegiatanSub,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: [""],
  methods: {
    close() {
      this.$root.$emit('bv::hide::modal-1', 'AddLevel')
    }
  },
  props: {
    rs_detail_kegiatan: {},
    rs_kegiatan: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>