<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <tabelkegiatan :rs_program="rs_program" @DetailKegiatan="DetailKegiatan"></tabelkegiatan>
        </b-card>
      </b-col>
    </b-row>
    <detail-kegiatan :rs_detail_kegiatan=rs_detail_kegiatan :rs_kegiatan="rs_kegiatan"> </detail-kegiatan>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs, BCard,
  BTab, BRow, BCol
} from 'bootstrap-vue'
import tabelkegiatan from './component/TabelKegiatan.vue'
import DetailKegiatan from './component/DetailKegiatan.vue'
export default {
  components: {
    BTabs, BCard,
    BTab, BRow, BCol,
    tabelkegiatan,
    DetailKegiatan,
  },
  props: {

  },
  data() {
    return {
      rs_program: [],
      rs_kegiatan: [],
      rs_detail_kegiatan: {}
    }
  },
  mixins: [Base],
  mounted() {
    this.load_data();
    this.load_kegiatan();
  },
  methods: {
    DetailKegiatan(val) {
      this.rs_detail_kegiatan = val
      this.load_kegiatan(val.id_program)
    },
    async load_data() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/kode_akun/load_data',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_program = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_kegiatan(id_program) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/kode_akun/load_kegiatan',
        data: {
          id_program: id_program
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_kegiatan = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>